import React, { useState, useEffect } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import CorpUsersForm from "@/forms/CorpUsersForm";
import { useCorpUsersStore, useModalStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ id, onUpdate = _noop } = {}) => {
  const [initCorpUser, setInitCorpUser] = useState(null);
  const { ...actions } = useCorpUsersStore();
  const { closeModal } = useModalStore();

  useEffect(() => {
    fetchById();
  }, []);

  const fetchById = async () => {
    try {
      const corpUserResult = await actions.fetchByIdOnTheFly(id);

      setInitCorpUser(corpUserResult);
    } catch (e) {
      window.alert(`기업회원을 조회할 수 없습니다. ${e.message}`);
    }
  };

  const onSubmit = async ({
    level,
    username,
    realname,
    corpTitle,
    contact,
    email,
    type,
    useMasking,
    useIndependentPostalCodes,
  }) => {
    actions
      .update(id, {
        level,
        username,
        realname,
        corpTitle,
        contact,
        email,
        type,
        useMasking: useMasking === "true",
        useIndependentPostalCodes: useIndependentPostalCodes === "true",
      })
      .then(() => {
        onUpdate();
        closeModal();
      })
      .catch((e) => {
        window.alert(`기업회원 수정에 실패했습니다: Status Code: ${e.status}`);
      });
  };

  return (
    <Modal size="lg">
      <Modal.Header>
        <Title>기업회원 수정</Title>
      </Modal.Header>
      <Modal.Body>
        <CorpUsersForm
          onSubmit={onSubmit}
          initCorpUser={initCorpUser}
          id={id}
        />
      </Modal.Body>
    </Modal>
  );
};
