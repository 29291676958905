export const CORP_USER_LEVEL_NORMAL = "일반";
export const CORP_USER_LEVEL_SUPER = "슈퍼";
export const CORP_USER_LEVEL_MANAGER = "매니저";

export const CORP_USER_TYPE_NORMAL = "일반기업";
export const CORP_USER_TYPE_POSTBOX = "포스트박스퀵";
export const CORP_USER_TYPE_PERSONAL = "개인사용자";

export class CorpUser {
  apiKey = null;
  apiToken = null;
  autoPrint = false;
  contact = "";
  corpTitle = "";
  deliveredAlimtalk = false;
  email = "";
  id = null;
  level = null;
  levelString = CORP_USER_LEVEL_NORMAL;
  logoImage = null;
  macAddress = null;
  realname = "";
  spots = [];
  type = null;
  typeString = CORP_USER_TYPE_NORMAL;
  username = "";
  etc = null;

  constructor(corpUser) {
    this.apiKey = corpUser.apiKey;
    this.apiToken = corpUser.apiToken;
    this.autoPrint = corpUser.autoPrint;
    this.contact = corpUser.contact;
    this.corpTitle = corpUser.corpTitle;
    this.deliveredAlimtalk = corpUser.deliveredAlimtalk;
    this.email = corpUser.email;
    this.id = corpUser.id;
    this.etc = corpUser.etc;
    this.type = corpUser.type;
    this.level = corpUser.level;

    switch (corpUser.level) {
      case "normal":
        this.levelString = CORP_USER_LEVEL_NORMAL;
        break;
      case "manager":
        this.levelString = CORP_USER_LEVEL_MANAGER;
        break;
      case "super":
        this.levelString = CORP_USER_LEVEL_SUPER;
        break;
      default:
        this.levelString = CORP_USER_LEVEL_NORMAL;
    }

    this.logoImage = corpUser.logoImage;
    this.macAddress = corpUser.macAddress;
    this.realname = corpUser.realname;
    this.spots = corpUser.spots;

    switch (corpUser.type) {
      case "NORMAL":
        this.typeString = CORP_USER_TYPE_NORMAL;
        break;
      case "POSTBOX":
        this.typeString = CORP_USER_TYPE_POSTBOX;
        break;
      case "PERSONAL":
        this.typeString = CORP_USER_TYPE_PERSONAL;
        break;
      default:
        this.typeString = CORP_USER_TYPE_NORMAL;
    }

    this.username = corpUser.username;
  }

  get etcObj() {
    try {
      return this.etc && JSON.parse(this.etc);
    } catch (e) {
      return {};
    }
  }

  get useMasking() {
    return !!this.etcObj?.useMasking || false;
  }

  get useIndependentPostalCodes() {
    return !!this.etcObj?.useIndependentPostalCodes || false;
  }
}
